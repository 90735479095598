<script setup>
import { getDescription, getTitle } from '@@/utils/PageUtils';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import { useUserStore } from '@@/stores/User';
import UserTrackingService from '@@/utils/UserTrackingService';

const userFavoritesStore = useUserFavoritesStore();
const userStore = useUserStore();

const config = useRuntimeConfig().public;
const route = useRoute();

const props = defineProps({
  // Set to false in page component if it will fetch the banner to ensure registration messages
  // are displayed to new users!
  canFetchBanner: {
    type: Boolean,
    default: true,
  },
  canGetUserBanner: {
    type: Boolean,
    default: false,
  },
  canSetDescriptionAndTitle: {
    type: Boolean,
    default: true,
  },
  canSetMetaImage: {
    type: Boolean,
    default: true,
  },
  canShowSelectFavoriteListDialog: {
    type: Boolean,
    default: false,
  },
  customContainerClass: {
    type: [Array, String],
    default: null,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  pageParams: {
    type: Object,
    default: null,
  },
  showPageContent: {
    type: Boolean,
    default: true,
  },
});

// ------------------------------------------------------------------------------------------------
// Computed Properties
// ------------------------------------------------------------------------------------------------

const { banner, isGuest } = storeToRefs(userStore);
const selectFavoriteListDialogProps = computed(() => userFavoritesStore.ui?.selectFavoriteListDialogProps);

const bannerClass = computed(() => props.fullScreen ? 'tw-my-4 tw-mx-1 md:tw-mx-4' : 'tw-mb-8');

const containerClass = computed(() => {
  if (props.customContainerClass) {
    return props.customContainerClass;
  }

  return props.fullScreen ? 'tw-w-screen tw-h-full tw-overflow-hidden' : 'container page-content';
});

const showDialog = computed(() => !!selectFavoriteListDialogProps.value);

// ------------------------------------------------------------------------------------------------
// Methods
// ------------------------------------------------------------------------------------------------

const handleDismissBanner = () => userStore.removeBanner();
const handleSelectFavoriteListDialogClose = () => userFavoritesStore.showSelectFavoriteListDialog(null);

// ------------------------------------------------------------------------------------------------
// Dynamic Document Head
// ------------------------------------------------------------------------------------------------

const { fullPath, path } = route;
let title;

useHead({
  link: [{ rel: 'canonical', href: `${config.baseUrl}${path}` }],
});

if (props.canSetDescriptionAndTitle === true) {
  const defaultDescription = 'Catch every powder day with the best snow forecast, snow report, and ski conditions information.';
  const params = { config, path, ...props.pageParams };

  const description = getDescription(params) || defaultDescription;
  title = getTitle(params) || config.siteName;

  useSeoMeta({
    description,
    ogDescription: description,
    ogTitle: title,
    ogUrl: () => `${config.baseUrl}${fullPath}`,
    title,
    twitterDescription: description,
    twitterTitle: title,
  });
}

if (props.canSetMetaImage) {
  const { ogImage } = config;
  useSeoMeta({
    ogImage: ogImage,
    twitterImage: ogImage,
  });
}

if (String(path).startsWith('/user/favorites')
  || String(path).startsWith('/user/notifications')
  || String(path).startsWith('/user/settings')
  || String(path).startsWith('/user/group')) {
  useSeoMeta({ robots: 'noindex,nofollow' });
}
else {
  const { robots = 'index,follow' } = config;
  useSeoMeta({ robots });
}

// ------------------------------------------------------------------------------------------------
// Lifecycle Hooks
// ------------------------------------------------------------------------------------------------

onMounted(async () => {
  if (props.canFetchBanner && !isGuest.value) {
    const { canGetUserBanner } = props;
    userStore.getBanner({ canGetUserBanner });
  }

  if (UserTrackingService.canTrackUser()) {
    await UserTrackingService.startBraze(route);
    UserTrackingService.initializeGtag();
    UserTrackingService.sendAdformTrackingPoints();
  }
});
</script>

<template>
  <div
    v-if="showPageContent"
    :class="containerClass"
  >
    <transition
      enter-active-class="fade-enter-transition"
      enter-from-class="fade-out"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <Banner
        v-if="banner"
        v-bind="banner"
        :class="bannerClass"
        @dismiss="handleDismissBanner"
      />
    </transition>
    <slot />
    <SelectFavoriteListDialog
      v-if="canShowSelectFavoriteListDialog"
      :show-dialog="showDialog"
      v-bind="selectFavoriteListDialogProps"
      @close="handleSelectFavoriteListDialogClose"
    />
  </div>
</template>
